import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'preachingsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'PreachingPoints', action: 'index' },
    component: lazyLoading('preaching-points/Index'),
  },
  {
    name: 'preachingsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'PreachingPoints', action: 'view' },
    component: lazyLoading('preaching-points/View'),
  },
  {
    name: 'preachingsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'PreachingPoints', action: 'edit' },
    component: lazyLoading('preaching-points/Edit'),
  },
  {
    name: 'preachingsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'PreachingPoints', action: 'add' },
    component: lazyLoading('preaching-points/New'),
  },
  {
    path: '',
    redirect: { name: 'preachingsIndex' },
  },
]
