import User from './user'

//     const BASE_URL = process.env.API_URL || 'http://localhost:8765/api/'
//     const BASE_URL = process.env.API_URL || 'http://rpts.jfhp.org/api/'
const BASE_URL = process.env.API_URL || 'https://dev.samnaz.org/api/'
const DEV_URL = process.env.NODE_ENV === 'development' ? BASE_URL : '/api/'

const state = {
  dashboardPeriod: null,
  user: User.from(localStorage.token),
  locale: localStorage.locale,
  sidebar: {
    opened: false,
  },
  config: {
    palette: {
      primary: '#6c7fee',
      secondary: '#6e7ff1',
      success: '#8ddc88',
      info: '#71baff',
      danger: '#f8706d',
      warning: '#ffd652',
      dark: '#34495e',
      white: '#fff',
      black: '#000',
      gray: '#333333',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd',
    },
    navbar_image: '',
    background_image: '',
    login_image: '',
    background_type: 'image',
    login_type: 'image',
  },
  notifications: {
    modal: false,
    status: [
      { name: 'notifications.status.unreaded', value: 0 },
      { name: 'notifications.status.readed', value: 1 },
    ],
  },
  isLoading: true,
}

const mutations = {
  updatePeriod (state, period) {
    state.dashboardPeriod = period
  },
  loginUser (state) {
    state.user = User.from(localStorage.token)
  },
  updatePermissions (state, data) {
    state.user.setPermission(data)
  },
  updateModules (state, data) {
    state.user.setModules(data)
  },
  logoutUser (state) {
    state.user = null
    delete localStorage.token
  },
  updateLocale (state, locale) {
    localStorage.setItem('locale', locale)
    state.locale = locale
    window.__localeId__ = state.locale
  },
  updateTheme (state, theme) {
    let baseUrl = ''
    if (process.env.NODE_ENV === 'development') {
      const patt = /^https?:\/\/[^/]+/g
      baseUrl = DEV_URL.match(patt)[0] || DEV_URL
    }
    if (theme === null) return

    state.config.navbar_image = baseUrl + theme.navbar_image
    state.config.background_image = baseUrl + theme.background_image
    state.config.login_image = baseUrl + theme.login_image
    state.config.background_type = theme.background_type
    state.config.login_type = theme.login_type
    state.config.palette = {
      ...state.config.palette,
      primary: theme.primary_color,
      success: theme.success_color,
      danger: theme.danger_color,
      info: theme.info_color,
      background: theme.background_color,
    }
  },
  patchTheme (state) {
    const root = document.documentElement
    const colors = Object.keys(state.config.palette)
    for (const color of colors) {
      root.style.setProperty(`--brand-${color}`, state.config.palette[color])
    }
  },
  setLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  openNotificationsModal (state) {
    state.notifications.modal = true
  },
  closeNotificationsModal (state) {
    state.notifications.modal = false
  },
}

const actions = {
}

export default {
  state,
  mutations,
  actions,
}
