import lazyLoading from './lazyLoading'

export default [
  {
    name: 'teamsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Teams', action: 'index' },
    component: lazyLoading('teams/Index'),
  },
  {
    name: 'teamsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Teams', action: 'view' },
    component: lazyLoading('teams/View'),
  },
  {
    name: 'teamsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Teams', action: 'edit' },
    component: lazyLoading('teams/Edit'),
  },
  {
    name: 'teamsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Teams', action: 'add' },
    component: lazyLoading('teams/New'),
  },
  {
    name: 'teamsInscription',
    path: 'inscription',
    meta: { requiresAuth: true, controller: 'TeamsYearPlans', action: 'add' },
    component: lazyLoading('teams/inscription/New'),
  },
  {
    name: 'teamsYearPlansIndex',
    path: 'index/inscription',
    redirect: { name: 'teamsIndex' },
  },
  {
    path: '',
    redirect: { name: 'teamsIndex' },
  },
]
