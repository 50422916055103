import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'importDataIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'ImportData', action: 'index' },
    component: lazyLoading('import-data/Index'),
  },
  {
    path: '',
    redirect: { name: 'importDataIndex' },

  },
]
