import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'reportsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'index' },
    component: lazyLoading('reports/Index'),
  },
  {
    name: 'reportsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'view' },
    component: lazyLoading('reports/View'),
  },
  {
    name: 'reportsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'edit' },
    component: lazyLoading('reports/Edit'),
  },
  {
    name: 'reportsReview',
    path: 'review/:id',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'approve' },
    component: lazyLoading('reports/Review'),
  },
  {
    name: 'reportsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'add' },
    component: lazyLoading('reports/New'),
  },
  {
    path: '',
    redirect: { name: 'reportsIndex' },
  },
]
