import lazyLoading from './lazyLoading'

export default [
  {
    name: 'formsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'DynamicForms', action: 'index' },
    component: lazyLoading('forms/Index'),
  },
  {
    name: 'formsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'DynamicForms', action: 'view' },
    component: lazyLoading('forms/View'),
  },
  {
    name: 'formsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'DynamicForms', action: 'edit' },
    component: lazyLoading('forms/Edit'),
  },
  {
    name: 'formsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'DynamicForms', action: 'add' },
    component: lazyLoading('forms/New'),
  },
  {
    path: '',
    redirect: { name: 'formsIndex' },
  },
]
