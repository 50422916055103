import lazyLoading from './lazyLoading'

export default [
  {
    name: 'mtiIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Mti', action: 'index' },
    component: lazyLoading('mti/Index'),
  },
  {
    name: 'mtiView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Mti', action: 'view' },
    component: lazyLoading('mti/View'),
  },
  {
    name: 'mtiEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Mti', action: 'edit' },
    component: lazyLoading('mti/Edit'),
  },
  {
    name: 'mtiNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Mti', action: 'add' },
    component: lazyLoading('mti/New'),
  },
  {
    path: '',
    redirect: { name: 'mtiIndex' },
  },
]
