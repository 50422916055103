import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'assignmentsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'assignments' },
    component: lazyLoading('reports/assignments/Index'),
  },
  {
    name: 'assignmentsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'assignments' },
    component: lazyLoading('reports/assignments/New'),
  },
  {
    name: 'assignmentsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Proyections', action: 'assignments' },
    component: lazyLoading('reports/assignments/Edit'),
  },
  {
    path: '',
    redirect: { name: 'assignmentsIndex' },
  },
]
