const config = state => state.app.config
const dashboardPeriod = state => state.app.dashboardPeriod
const palette = state => state.app.config.palette
const loginImage = state => state.app.config.login_image
const loginBackgroundType = state => state.app.config.login_type
const navbarImage = state => state.app.config.navbar_image
const backgroundImage = state => state.app.config.background_image
const backgroundType = state => state.app.config.background_type
const isLoading = state => state.app.isLoading
const currentUser = state => state.app.user
const notificationModal = state => state.app.notifications.modal
const notificationStatus = state => state.app.notifications.status
const isUserLogged = state => state.app.user != null
const currentLocale = state => state.app.locale

export {
  config,
  dashboardPeriod,
  palette,
  loginImage,
  loginBackgroundType,
  navbarImage,
  backgroundImage,
  backgroundType,
  isLoading,
  currentUser,
  notificationModal,
  notificationStatus,
  isUserLogged,
  currentLocale,
}
