import TextLoading from '@/components/extras/Text/TextLoading'
import ErrorNotification from '@/components/extras/Notifications/ErrorNotification'
import RetryNotification from '@/components/extras/Notifications/RetryNotification'
import LoadingNotification from '@/components/extras/Notifications/LoadingNotification'

const Components = {
  install (Vue) {
    Vue.component('text-loading', TextLoading)
    Vue.component('error-notification', ErrorNotification)
    Vue.component('retry-notification', RetryNotification)
    Vue.component('loading-notification', LoadingNotification)
  },
}

export default Components
