<template>
  <app-page-layout
    class="app-layout"
    :is-top-bar.sync="isTopBar"
    :minimized.sync="minimized"
    :mobile-width="mobileWidth"
  >
    <app-navbar
      class="app-layout__navbar"
      :is-top-bar.sync="isTopBar"
      :minimized.sync="minimized"
    />
    <app-topbar
      class="app-layout__topbar"
      v-if="isTopBar"
    />
    <div class="app-layout__container">
      <app-sidebar
        class="app-layout__sidebar"
        v-if="!isTopBar"
        :minimized="minimized"
      />
      <div
        class="app-layout__main"
        :class="{'app-layout__main--top': isTopBar}"
      >
        <main
          class="app-layout__main-layout layout fluid gutter--xl"
          slot="content"
          role="main"
        >
          <va-modal
            ref="userNotificationsModal"
            v-model="userNotificationModal"
            :max-width="'100vw'"
            :title="$t('layout.modals.notifications')"
            :hide-default-actions="true"
            :fullscreen="true"
            @cancel="closeNotificationModal"
          >
            <user-notifications />
          </va-modal>
          <router-view />
          <div class="app-layout__main--footer">
            <span>
              v{{ appVersion }} {{ $t('layout.powered') }}&nbsp;
              <a
                href="https://medianet.com.ve"
                target="_blank"
              >
                Medianet
              </a>
            </span>
          </div>
        </main>
      </div>
    </div>
  </app-page-layout>
</template>

<script>
import { originalTheme, corporateTheme } from 'vuestic-ui/src/services/themes'
import {
  ColorThemeActionsMixin,
  ColorThemeMixin,
} from '../../services/vuestic-ui'
import { version } from '../../../package.json'
import { mapGetters } from 'vuex'

const AppPageLayout = () => import(/* webpackPrefetch: true */ './AppPageLayout')
const AppNavbar = () => import(/* webpackPrefetch: true */ './app-navbar/AppNavbar')
const AppTopbar = () => import(/* webpackPrefetch: true */ './app-topbar/AppTopbar')
const AppSidebar = () => import(/* webpackPrefetch: true */ './app-sidebar/AppSidebar')
const UserNotifications = () => import(/* webpackPrefetch: true */ '@/components/extras/UserNotifications')

export default {
  name: 'app-layout',
  components: {
    AppPageLayout,
    AppNavbar,
    AppTopbar,
    AppSidebar,
    UserNotifications,
  },
  data () {
    return {
      userNotificationModal: false,
      isTopBar: false,
      minimized: false,
      mobileWidth: 767,
    }
  },
  computed: {
    ...mapGetters(['notificationModal']),
    appVersion: () => version,
  },
  inject: ['contextConfig'],
  mixins: [ColorThemeActionsMixin, ColorThemeMixin],
  watch: {
    minimized (val) {
      window.localStorage.setItem('sidebar_minimized', JSON.stringify(val))
    },
    notificationModal (val) {
      this.userNotificationModal = val
    },
  },
  created () {
    if (this.$route.query && this.$route.query.theme === 'corporate') {
      this.setTheme('corporate')
    }
    this.setTheme('corporate')
    this.$root.$on('change-theme', this.setTheme)
  },
  beforeDestroy () {
    this.$root.$off('change-theme', this.setTheme)
  },
  methods: {
    closeNotificationModal () {
      this.$store.commit('closeNotificationsModal')
    },
    setTheme (themeName) {
      const theme = themeName === 'corporate' ? corporateTheme : originalTheme
      this.$store.commit('patchTheme')
      const colors = {
        ...theme.colors,
        ...this.$store.getters.palette,
      }
      this.setColors(colors)
      Object.keys(theme.context).forEach((key) => {
        this.contextConfig[key] = theme.context[key]
      })
    },
  },
}
</script>

<style lang="scss">
.app-layout {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    // TODO Probably there is a better way to achieve this.
    height: calc(100% - 65px);

    @include media-breakpoint-down(sm) {
      height: calc(100% - 110px);
    }
  }

  &__main {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    max-height: 100%;
    min-height: 100%;

    /*
    &--top {
    }
    */

    &--footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
      padding-bottom: 10px;
      position: relative;
      bottom: 0;
      height: auto;
      width: 100%;
    }

    &-layout {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: auto;
      box-sizing: border-box;
      min-height: 100%;
      margin: 0;
    }
  }
}
</style>
