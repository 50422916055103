<template>
  <div>
    <div v-show="!loading">
      <slot name="icon">
        <i
          class="mr-1"
          :class="icon"
          v-show="hasIcon"
        ></i>
      </slot>
      <slot>
        {{$t('auth.login')}}
      </slot>
    </div>
    <div v-show="loading">
      <slot name="loader">
        <looping-rhombuses-spinner
          :color="color"
          :rhombus-size="size"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import LoopingRhombusesSpinner from 'epic-spinners/src/components/lib/LoopingRhombusesSpinner.vue'

export default {
  name: 'text-loading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 15,
    },
  },
  components: { LoopingRhombusesSpinner },
  computed: {
    hasIcon () {
      return this.icon.length > 0
    },
  },
}
</script>

<style>
</style>
