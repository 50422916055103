import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'logsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Logs', action: 'index' },
    component: lazyLoading('logs/Index'),
  },
  {
    name: 'logsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Logs', action: 'view' },
    component: lazyLoading('logs/View'),
  },
  {
    name: 'logsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Logs', action: 'edit' },
    component: lazyLoading('logs/Edit'),
  },
  {
    name: 'logsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Logs', action: 'add' },
    component: lazyLoading('logs/New'),
  },
  {
    path: '',
    redirect: { name: 'logsIndex' },
  },
]
