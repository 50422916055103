<template>
  <div
    id="app"
    class="app"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Flatpickr from 'flatpickr'
import NProgress from 'nprogress'
import { EventBus } from '../services/event-bus'

import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import { loadLanguageAsync } from '@/i18n'

const unauthRoute = /(\/auth\/login\.*)|(\/auth\/register\/\w+)|(\/auth\/recovery\.*)|(\/auth\/confirm\.*)/g

export default {
  name: 'app',
  data () {
    return {
      // Temporary config for 2.1.
      contextConfig: {
        gradient: false,
        shadow: 'lg', // 3 states: 'sm', 'lg', undefined (no shadow).
        invertedColor: false,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
  },
  provide () {
    return {
      contextConfig: this.contextConfig,
    }
  },
  watch: {
    // Temporary colors fix for 2.1.
    'contextConfig.invertedColor' (val) {
      const invertedColorClass = 'va-inverted-color'
      if (val) {
        document.body.classList.add(invertedColorClass)
      } else {
        document.body.classList.remove(invertedColorClass)
      }
    },
  },
  created () {
    this.updateTheme()
    if (localStorage.token) {
      this.updateUserToken()
    }

    this.$http.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (!error.response) {
          this.serverError()
          return Promise.reject(error)
        }
        this.showResponseError(error)

        return Promise.reject(error)
      },
    )

    this.checkCurrentLogin()

    this.adjustLocale(this.currentLocale || this.$root.$i18n.locale)
    EventBus.$on('locale:updated', (locale) => {
      this.adjustLocale(locale)
    })

    this.$router.beforeEach((to, from, next) => {
      NProgress.start()
      this.$store.commit('setLoading', true)
      const nextRoute = this.onEachRoute(to, from, next)

      if (nextRoute) next(nextRoute)
      else next()
    })

    this.$router.afterEach((to, from) => {
      NProgress.done()
      this.$store.commit('setLoading', false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    this.$router.onError(err => {
      NProgress.done(true)
      this.showToast(err.message, {
        icon: 'fa-times',
        position: 'top-right',
      })
      console.error('Error de ruta', err)
    })
  },
  methods: {
    adjustLocale (locale) {
      if (locale != null) {
        this.$root.$i18n.locale = locale
      } else {
        const lang = navigator.language.split('-')
        if (lang[0].match('esp|eng|por')) {
          this.$root.$i18n.locale = lang[0]
          locale = lang[0]
        }
      }
      window.__localeId__ = locale
      loadLanguageAsync(locale)

      switch (locale) {
        case 'esp': Flatpickr.localize(Spanish); break
        case 'por': Flatpickr.localize(Portuguese); break
        case 'eng': default: Flatpickr.localize(english); break
      }

      this.$http.defaults.headers.common['Accept-Language'] = locale
    },
    checkCurrentLogin (route) {
      route = route || this.$route.path
      if (route === '/') return
      if (!this.currentUser && !route.match(unauthRoute)) {
        this.unauthorizeRedirect(route)
      }
    },
    checkRouteAccess (route) {
      if (!route) return false

      let authorized = false
      const forced = route.meta.forcedPermission

      if (forced) {
        authorized = true
      } else if (this.currentUser.can(route.meta.controller, route.meta.action)) {
        authorized = true
      }

      return authorized
    },
    showResponseError (error) {
      if (!error.response) return
      switch (error.response.status) {
        case 401:
          this.unauthorizeRedirect(this.$route.path)
          break
        case 403:
          this.forbiddenRedirect()
          break
        case 422:
          this.unprocessableEntity(error.response.data.data)
          break
        case 500:
          this.serverError()
          break
      }
    },
    unprocessableEntity (data) {
      const fields = Object.keys(data.errors)
      for (const f of fields) {
        this.validationError(f)
      }
    },
    forbiddenRedirect () {
      this.showToast(this.$t('notifications.auth.unauthorized'), {
        icon: 'fa-times',
        position: 'top-right',
      })

      this.$router.push({
        name: 'dashboard',
      })
    },
    unauthorizeRedirect (route, pathOnly) {
      let query = null
      if (route && typeof route === 'object') {
        route = route.path
      }

      console.log('current route', route)
      if (unauthRoute.test(route) || route.includes('/auth/login')) {
        return
      }

      if (!unauthRoute.test(route) && route !== '/') {
        query = { redirect: route }
      }

      this.showToast(this.$t('notifications.auth.unauthorized'), {
        icon: 'fa-times',
        position: 'top-right',
      })

      const path = { name: 'login', query }
      return pathOnly ? path : this.$router.push(path)
    },
    validationError (field) {
      const f = this.$t(`attributes.${field}`)
      this.showToast(this.$t('notifications.network.unvalidated', { field: f }), {
        icon: 'fa-times',
        position: 'top-right',
      })
    },
    serverError () {
      this.showToast(this.$t('notifications.network.error'), {
        icon: 'fa-times',
        position: 'top-right',
      })
    },
    onEachRoute (to, from, next) {
      if (!to.matched.some(r => r.meta.requiresAuth)) return

      if (!this.currentUser) {
        console.log('to route', to)
        return this.unauthorizeRedirect(to, true)
      }

      let authorized = false
      const nextRoute = to.matched.find(r => r.path === to.path)
      if (nextRoute !== null) {
        if (this.checkRouteAccess(nextRoute || to)) {
          authorized = true
          return null
        }
      }

      if (!authorized) {
        this.showToast(this.$t('notifications.auth.unauthorized'), {
          icon: 'fa-times',
          position: 'top-right',
        })

        return { name: 'dashboard' }
      }
    },
    async updateUserToken () {
      let t = false
      try {
        t = await this.$http.post('auth/login')
      } catch (err) {
        console.error(err)
        this.$store.commit('logoutUser')
        delete this.$http.defaults.headers.common.Authorization
        delete localStorage.token
        this.checkCurrentLogin()
        return
      }

      localStorage.token = t.data.data.token
      this.$http.defaults.headers.common.Authorization = 'Bearer ' + localStorage.token
      this.$store.commit('loginUser')
      this.updateUserModules()
      this.updateUserPermissions()
    },
    async updateUserPermissions () {
      let p = null
      try {
        p = await this.$http.get('permissions')
      } catch (e) {
        console.error('Error while getting permissions', e)
        return
      }

      this.$store.commit('updatePermissions', p.data.data)
    },
    async updateUserModules () {
      let p = null
      try {
        p = await this.$http.get('regions/modules/user')
      } catch (e) {
        console.error('Error while getting permissions', e)
        return
      }

      this.$store.commit('updateModules', p.data.data)
    },
    async updateTheme () {
      let t = {}
      try {
        t = await this.$http.get('themes/active')
      } catch (e) {
        return console.log('Couldn\'t update theme', e)
      }

      if (!t.data) return
      this.$store.commit('updateTheme', t.data)
      this.$store.commit('patchTheme')
      this.$themes.primary = t.data.primary_color
      this.$themes.success = t.data.success_color
      this.$themes.danger = t.data.danger_color
      this.$themes.info = t.data.info_color
      this.$themes.background = t.data.background_color
      this.$themes.gray = '#333333'
    },
  },
}
</script>

<style lang="scss">
@import "../sass/main.scss";

body {
  height: 100%;

  #app {
    height: 100%;
  }
}
</style>
