import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'trainingTypesIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'TrainingTypes', action: 'index' },
    component: lazyLoading('trainings/types/Index'),
  },
  {
    name: 'trainingTypesView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'TrainingTypes', action: 'view' },
    component: lazyLoading('trainings/types/View'),
  },
  {
    name: 'trainingTypesEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'TrainingTypes', action: 'edit' },
    component: lazyLoading('trainings/types/Edit'),
  },
  {
    name: 'trainingTypesNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'TrainingTypes', action: 'add' },
    component: lazyLoading('trainings/types/New'),
  },
  {
    path: '',
    redirect: { name: 'trainingTypesIndex' },
  },
]
