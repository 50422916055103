import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'usersIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Users', action: 'index' },
    component: lazyLoading('users/Index'),
  },
  {
    name: 'usersView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Users', action: 'view' },
    component: lazyLoading('users/View'),
  },
  {
    name: 'usersEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Users', action: 'edit' },
    component: lazyLoading('users/Edit'),
  },
  {
    name: 'usersNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Users', action: 'add' },
    component: lazyLoading('users/New'),
  },
  {
    path: '',
    redirect: { name: 'usersIndex' },
  },
]
