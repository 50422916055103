import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'countryReportsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'CountryReports', action: 'view' },
    component: lazyLoading('reports/country/View'),
  },
  {
    name: 'countryReportsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'CountryReports', action: 'edit' },
    component: lazyLoading('reports/country/Edit'),
  },
  {
    path: '',
    redirect: { name: 'reportsIndex' },
  },
]
