import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from '../store/axios'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'eng', // set locale
  fallbackLocale: 'eng',
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang, force = false) {
  if (loadedLanguages.includes(lang) && !force) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  return axios.get(`/langs/get/${lang}`).then(response => {
    const msgs = response.data.data
    // console.log('msgs', msgs)
    if (loadedLanguages.indexOf(lang) !== -1) {
      loadedLanguages.push(lang)
    }

    i18n.setLocaleMessage(lang, JSON.parse(msgs.translation))
    setI18nLanguage(lang)
  })
}
