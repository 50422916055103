<template>
  <div
    class="auth-layout row align-content--center background_img"
    :style="logoImg"
  >
    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <language-dropdown class="app-navbar-actions__item float-right" color="var(--brand-primary)"/>
          <router-link
            class="py-5 flex-center auth-layout__logo"
            to="/auth/login"
          >
          </router-link>
          <va-tabs
            v-model="tabIndex"
            center
          >
            <va-tab>{{ $t('auth.login') }}</va-tab>
            <!-- <va-tab>{{ $t('auth.createNewAccount') }}</va-tab> -->
          </va-tabs>

          <va-separator />

          <div class="pa-3">
            <router-view />
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ColorThemeMixin } from '../../services/vuestic-ui'
const LanguageDropdown = () => import(/* webpackPrefetch: true */ '../admin/app-navbar/components/dropdowns/LanguageDropdown.vue')

const tabs = [
  'login',
]

export default {
  name: 'auth-layout',
  components: {
    LanguageDropdown,
  },
  mixins: [ColorThemeMixin],
  data () {
    return {
      logoImg: '',
      gradient: '',
      selectedTabIndex: 0,
      tabTitles: ['login'],
    }
  },
  computed: {
    ...mapGetters(['loginImage', 'loginBackgroundType']),
    tabIndex: {
      set (tabIndex) {
        this.$router.push({ name: tabs[tabIndex] })
      },
      get () {
        return tabs.indexOf(this.$route.name)
      },
    },
  },
  watch: {
    loginImage (val) {
      this.backgroundImg()
    },
  },
  created () {
    this.backgroundImg()
  },
  methods: {
    backgroundImg () {
      const c = this.loginImage || ''
      if (this.loginBackgroundType === 'image') {
        this.logoImg = `background-image: url('${c}')`
      } else {
        const background = this.$themes.background
        this.logoImg = `background-color: ${background}`
      }
    },
  },
}
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;

  &.background_img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__logo {
    width: auto;
    height: 20vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./../../assets/evangelismo.svg");
  }

  &__card {
    width: 100%;
    max-width: 600px;

    .float-right {
      float: right;
    }
  }

  &__options {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}
</style>
