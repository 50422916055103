import lazyLoading from './lazyLoading'

export default [
  {
    name: 'resourcesIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Resources', action: 'index' },
    component: lazyLoading('resources/Index'),
  },
  {
    name: 'resourcesEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Resources', action: 'edit' },
    component: lazyLoading('resources/Edit'),
  },
  {
    name: 'resourcesNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Resources', action: 'add' },
    component: lazyLoading('resources/New'),
  },
  {
    path: '',
    redirect: { name: 'resourcesIndex' },
  },
]
