import JwtDecode from 'jwt-decode'
import { EventBus } from '../../services/event-bus'

const idKey = 'sub'
const emailKey = 'email'
const nameKey = 'name'
const avatarKey = 'avatar'
const groupKey = 'groups'
const teamKey = 'team'
const districtKey = 'district'
const countryKey = 'country'
const regionKey = 'region'
const superKey = 'super'

export default class User {
  static from (token) {
    try {
      const obj = JwtDecode(token)
      return new User(obj)
    } catch (_) {
      return null
    }
  }

  // eslint-disable-next-line space-before-function-paren
  constructor(token) {
    this.id = token[idKey]
    this.email = token[emailKey]
    this.name = token[nameKey]
    this.avatar = token[avatarKey]
    this.super = token[superKey]
    this.groups = token[groupKey] || []
    this.team = token[teamKey]
    this.district = token[districtKey]
    this.country = token[countryKey]
    this.region = token[regionKey]
    this.permissions = []
    this.modules = []
  }

  setPermission (permission) {
    if (Array.isArray(permission)) {
      this.permissions = permission.slice(0)
    } else {
      this.permissions = permission
    }
    EventBus.$emit('user:permissions')
  }

  setModules (module) {
    if (Array.isArray(module)) {
      this.modules = module.slice(0)
    } else {
      this.modules = module
    }
    EventBus.$emit('user:modules')
  }

  hasGroups () {
    return this.groups.length > 0
  }

  hasGroup (groups) {
    if (!Array.isArray(groups)) {
      groups = [groups]
    }
    if (!this.hasGroups()) return false

    const g = this.groups
    const intersection = g.filter(x => groups.includes(x.id))

    return intersection.length > 0
  }

  hasModule (moduleId) {
    let allow = false
    const mod = this.modules.find(x => x.module_id === moduleId)
    if (mod) {
      allow = true
    }

    return allow
  }

  can (controller, action) {
    let allow = false
    const keys = Object.keys(this.permissions)
    for (const key of keys) {
      allow = this.allowed(this.permissions[key], controller, action)
      if (allow === true) {
        break
      }
    }

    return allow
  }

  allowed (permissions, controller, action) {
    if (!permissions || !permissions[controller]) {
      return false
    }

    let compare = action
    let property = 'alias'

    if (!compare) {
      compare = true
      property = 'allow'
    }

    const permission = permissions[controller].find(p => p[property] === compare)

    return permission != null ? permission.allow : false
  }

  any (controllers, actions) {
    if (!Array.isArray(controllers)) {
      controllers = [controllers]
    }
    if (!Array.isArray(actions)) {
      actions = [actions]
    }

    let allow = false
    for (const controller of controllers) {
      for (const action of actions) {
        allow = this.can(controller, action)
        if (allow === true) {
          return allow
        }
      }
    }
    return allow
  }

  all (controllers, actions) {
    if (!Array.isArray(controllers)) {
      controllers = [controllers]
    }
    if (!Array.isArray(actions)) {
      actions = [actions]
    }

    let allow = true
    for (const controller of controllers) {
      for (const action of actions) {
        allow = this.can(controller, action)
        if (allow !== true) {
          return allow
        }
      }
    }
    return allow
  }
}
