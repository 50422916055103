import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'inventoryRequestsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'InventoryRequests', action: 'index' },
    component: lazyLoading('inventory/requests/Index'),
  },
  {
    name: 'inventoryRequestsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'InventoryRequests', action: 'view' },
    component: lazyLoading('inventory/requests/View'),
  },
  {
    name: 'inventoryRequestsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'InventoryRequests', action: 'edit' },
    component: lazyLoading('inventory/requests/Edit'),
  },
  {
    name: 'inventoryRequestsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'InventoryRequests', action: 'add' },
    component: lazyLoading('inventory/requests/New'),
  },
  {
    path: '',
    redirect: { name: 'inventoryRequestsIndex' },
  },
]
