import formatDate from 'date-fns/format'
import enGB from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import ptBR from 'date-fns/locale/pt-BR'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

const Locales = { eng: enGB, esp: es, por: ptBR }

const $dateObj = {
  locales: function (locale) {
    return Locales[locale]
  },
  parse: (date) => {
    if (!date.includes('T')) {
      date += 'T00:00:00.000Z'
    }
    return utcToZonedTime(date)
  },
  format: (Vue, date, format) => {
    const local = $dateObj.locales(localStorage.getItem('locale'))
    if (typeof date === 'string') {
      date = $dateObj.parse(date)
    }
    const dateFormatted = formatDate(date, format, {
      locale: local,
    })
    return dateFormatted
  },
}

const Filters = {
  install (Vue) {
    Vue.prototype.$date = {
      locales: (locale) => $dateObj.locales(locale),
      parse: (date) => $dateObj.parse(date),
      format: (date, format) => $dateObj.format(Vue, date, format),
    }

    Vue.filter('byterize', function (bytes, decimals, si) {
      si = si || true
      decimals = decimals || 2

      const thresh = si ? 1000 : 1024
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }
      const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      do {
        bytes /= thresh
        ++u
      } while (Math.abs(bytes) >= thresh && u < units.length - 1)
      return bytes.toFixed(decimals) + ' ' + units[u]
    })

    Vue.filter('date', function (value) {
      if (value) {
        return $dateObj.format(Vue, value, 'dd/MM/yyyy')
      }
    })

    Vue.filter('truncate', function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    })
  },
}

export default Filters
