<template>
  <div
    class="flex"
    v-show="show"
    :class="sizes"
  >
    <va-notification color="info">
      <va-badge color="info">{{ $t('notifications.alerts.info') }}</va-badge>
      <slot>
        <span>
          {{ $t('notifications.loading.label') }}
        </span>
      </slot>
    </va-notification>
  </div>
</template>

<script>
export default {
  name: 'loading-notification',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    sizes: {
      type: String,
      default: 'xs12',
    },
  },
}
</script>
