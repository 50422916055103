import lazyLoading from '../lazyLoading'

const DistrictRoutes = [
  {
    name: 'districtsIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'Districts', action: 'index' },
    component: lazyLoading('districts/Index'),
  },
  {
    name: 'districtsView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'Districts', action: 'view' },
    component: lazyLoading('districts/View'),
  },
  {
    name: 'districtsEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'Districts', action: 'edit' },
    component: lazyLoading('districts/Edit'),
  },
  {
    name: 'districtsNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'Districts', action: 'add' },
    component: lazyLoading('districts/New'),
  },
  {
    path: '',
    redirect: { name: 'districtsIndex' },
  },
]

const DistrictPlanRoutes = [
  {
    name: 'districtPlansIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'DistrictPlans', action: 'index' },
    component: lazyLoading('districts/planification/Index'),
  },
  {
    name: 'districtPlansView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'DistrictPlans', action: 'view' },
    component: lazyLoading('districts/planification/View'),
  },
  {
    name: 'districtPlansEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'DistrictPlans', action: 'edit' },
    component: lazyLoading('districts/planification/Edit'),
  },
  {
    name: 'districtPlansNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'DistrictPlans', action: 'add' },
    component: lazyLoading('districts/planification/New'),
  },
  {
    path: '',
    redirect: { name: 'districtPlansIndex' },
  },
]

export { DistrictRoutes, DistrictPlanRoutes }
