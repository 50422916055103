import axios from 'axios'
import NProgress from 'nprogress'

//            const DEV_URL = process.env.API_URL || 'http://rpts.jfhp.org/api/'
const DEV_URL = process.env.API_URL || 'https://dev.samnaz.org/api/'
//            const DEV_URL = process.env.API_URL || 'http://localhost:8765/api/'
const API_URL = process.env.NODE_ENV === 'development' ? DEV_URL : '/api/'

const instance = axios.create({
  baseURL: API_URL,
})
instance.defaults.headers.common.Accept = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'
const token = localStorage.token
if (token) {
  instance.defaults.headers.common.Authorization = 'Bearer ' + token
}
// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  if (!config.withoutLoader) {
    NProgress.start()
  }
  return config
}, error => {
  NProgress.done()
  return Promise.reject(error)
})

// before a response is returned stop nprogress
instance.interceptors.response.use(response => {
  if (!response.config.withoutLoader) {
    NProgress.done()
  }
  return response
}, error => {
  NProgress.done()
  return Promise.reject(error)
})
export default instance
