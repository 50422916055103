<template>
  <div
    class="flex"
    v-show="show"
    :class="sizes"
  >
    <va-notification
      color="danger"
    >
      <va-badge color="danger">{{ $t('notifications.alerts.error') }}</va-badge>
      <slot></slot>
    </va-notification>
  </div>
</template>

<script>
export default {
  name: 'error-notification',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    sizes: {
      type: String,
      default: 'xs12',
    },
  },
}
</script>
