import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'profileView',
    path: 'view',
    meta: { requiresAuth: true, forcedPermission: true },
    component: lazyLoading('users/Profile/View'),
  },
  {
    name: 'profileEdit',
    path: 'edit',
    meta: { requiresAuth: true, forcedPermission: true },
    component: lazyLoading('users/Profile/Edit'),
  },
  {
    path: '',
    redirect: { name: 'profileView' },
  },
]
