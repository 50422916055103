// import VaAccordion from 'vuestic-ui/src/components/vuestic-components/va-collapse/VaAccordion'
// import VaAvatar from 'vuestic-ui/src/components/vuestic-components/va-avatar/VaAvatar'
import VaBadge from 'vuestic-ui/src/components/vuestic-components/va-chip/VaBadge.vue'
import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton.vue'
import VaButtonGroup
  from 'vuestic-ui/src/components/vuestic-components/va-button-group/VaButtonGroup.vue'
import VaButtonToggle
  from 'vuestic-ui/src/components/vuestic-components/va-button-toggle/VaButtonToggle.vue'
import VaCard from 'vuestic-ui/src/components/vuestic-components/va-card/VaCard'
import VaCheckbox from 'vuestic-ui/src/components/vuestic-components/va-checkbox/VaCheckbox.vue'
// import VaChip from 'vuestic-ui/src/components/vuestic-components/va-chip/VaChip.vue'
import VaCollapse from 'vuestic-ui/src/components/vuestic-components/va-collapse/VaCollapse'
// import VaCountBadge from 'vuestic-ui/src/components/vuestic-components/va-count-badge/VaCountBadge'
import VaDataTable from '@/components/extras/Customs/VaDataTable.vue'
import VaDatePicker from 'vuestic-ui/src/components/vuestic-components/va-date-picker/VaDatePicker'
import VaDropdown from 'vuestic-ui/src/components/vuestic-components/va-dropdown/VaDropdown'
// import VaFeed from 'vuestic-ui/src/components/vuestic-components/va-feed/VaFeed.vue'
import VaFileUpload from 'vuestic-ui/src/components/vuestic-components/va-file-upload/VaFileUpload'
import VaIcon from 'vuestic-ui/src/components/vuestic-components/va-icon/VaIcon'
import VaInnerLoading from '@/components/extras/Customs/VaInnerLoading'
import VaInput from 'vuestic-ui/src/components/vuestic-components/va-input/VaInput'
import VaItem from 'vuestic-ui/src/components/vuestic-components/va-list/VaItem'
import VaItemLabel from 'vuestic-ui/src/components/vuestic-components/va-list/VaItemLabel'
import VaItemSection from 'vuestic-ui/src/components/vuestic-components/va-list/VaItemSection'
import VaList from 'vuestic-ui/src/components/vuestic-components/va-list/VaList'
import VaListLabel from 'vuestic-ui/src/components/vuestic-components/va-list/VaListLabel'
import VaListSeparator from 'vuestic-ui/src/components/vuestic-components/va-list/VaListSeparator'
import VaModal from 'vuestic-ui/src/components/vuestic-components/va-modal/VaModal.vue'
// import VaNavbar from 'vuestic-ui/src/components/vuestic-components/va-navbar/VaNavbar'
import VaNotification
  from 'vuestic-ui/src/components/vuestic-components/va-notification/VaNotification.vue'
import VaPagination from 'vuestic-ui/src/components/vuestic-components/va-pagination/VaPagination.vue'
import VaPopover from 'vuestic-ui/src/components/vuestic-components/va-popover/VaPopover.vue'
// import VaPopup from 'vuestic-ui/src/components/vuestic-components/va-popup/popup/VaPopup.vue'
import VaProgressBar
  from 'vuestic-ui/src/components/vuestic-components/va-progress-bar/progress-types/VaProgressBar.vue'
// import VaProgressCircle from 'vuestic-ui/src/components/vuestic-components/va-progress-bar/progress-types/VaProgressCircle.vue'
import VaRadioButton from 'vuestic-ui/src/components/vuestic-components/va-radio-button/VaRadioButton'
// import VaRating from 'vuestic-ui/src/components/vuestic-components/va-rating/VaRating'
// import VaScrollbar from 'vuestic-ui/src/components/vuestic-components/va-scrollbar/VaScrollbar.vue'
import VaSelect from '@/components/extras/Customs/VaSelect.vue'
import VaSeparator from 'vuestic-ui/src/components/vuestic-components/va-card/VaSeparator'
// import VaSidebar from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebar'
// import VaSidebarLink from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebarLink'
// import VaSidebarLinkGroup from 'vuestic-ui/src/components/vuestic-components/va-sidebar/VaSidebarLinkGroup'
import VaSlider from 'vuestic-ui/src/components/vuestic-components/va-slider/VaSlider.vue'
// import VaSocialNews from 'vuestic-ui/src/components/vuestic-components/va-social-news/VaSocialNews.vue'
import VaTab from 'vuestic-ui/src/components/vuestic-components/va-tabs/VaTab.vue'
import VaTabs from 'vuestic-ui/src/components/vuestic-components/va-tabs/VaTabs.vue'
// import VaTimeline from 'vuestic-ui/src/components/vuestic-components/va-timeline/VaTimeline'
// import VaTimelineItem from 'vuestic-ui/src/components/vuestic-components/va-timeline/VaTimelineItem'
// import VaToggle from 'vuestic-ui/src/components/vuestic-components/va-toggle/VaToggle.vue'
// import VaTopbar from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbar'
// import VaTopbarLink from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbarLink'
// import VaTopbarLinkGroup from 'vuestic-ui/src/components/vuestic-components/va-topbar/VaTopbarLinkGroup'
import VaTreeCategory from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeCategory'
import VaTreeNode from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeNode'
import VaTreeRoot from 'vuestic-ui/src/components/vuestic-components/va-tree-view/VaTreeRoot'
import VaInputWrapper from 'vuestic-ui/src/components/vuestic-components/va-input/VaInputWrapper'
import StickyScroll from 'vuestic-ui/src/components/vuestic-directives/StickyScroll'
// import VaButtonDropdown from 'vuestic-ui/src/components/vuestic-components/va-button-dropdown/VaButtonDropdown'
import { installPlatform } from 'vuestic-ui/src/components/vuestic-components/va-popup/install'
import { DropdownPopperPlugin } from 'vuestic-ui/src/components/vuestic-components/va-dropdown/dropdown-popover-subplugin'

import { registerVuesticObject } from 'vuestic-ui/src/components/resize-events'
// import VaSimplePalettePicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaSimplePalettePicker'
// import VaColorSquare from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaColorSquare'
// import VaSliderColorPicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaSliderColorPicker'
// import VaAdvancedColorPicker from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaAdvancedColorPicker'
// import VaColorPickerInput from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaColorPickerInput'
import VaPaletteCustom
  from 'vuestic-ui/src/components/vuestic-components/va-color-picker/VaPaletteCustom'
import { ToastedMixin } from 'vuestic-ui/src/components/vuestic-mixins/VuesticToasted'

import { BusPlugin } from 'vue-epic-bus'

installPlatform()

const VuesticPlugin = {
  install (Vue, options) {
    [
      // VaAccordion,
      // VaAvatar,
      VaBadge,
      VaButton,
      VaButtonGroup,
      VaButtonToggle,
      VaCard,
      VaCheckbox,
      // VaChip,
      VaCollapse,
      // VaCountBadge,
      VaDataTable,
      VaDatePicker,
      VaDropdown,
      // VaFeed,
      VaFileUpload,
      VaIcon,
      VaInnerLoading,
      VaInput,
      VaInputWrapper,
      VaItem,
      VaItemLabel,
      VaItemSection,
      VaList,
      VaListLabel,
      VaListSeparator,
      VaModal,
      // VaNavbar,
      VaNotification,
      VaPagination,
      VaPopover,
      // VaPopup,
      VaProgressBar,
      // VaProgressBar,
      // VaProgressCircle,
      VaRadioButton,
      // VaRating,
      // VaScrollbar,
      VaSelect,
      VaSeparator,
      // VaSidebar,
      // VaSidebarLink,
      // VaSidebarLinkGroup,
      VaSlider,
      // VaSocialNews,
      VaTab,
      VaTabs,
      // VaTimeline,
      // VaTimelineItem,
      // VaToggle,
      // VaTopbar,
      // VaTopbarLink,
      // VaTopbarLinkGroup,
      VaTreeCategory,
      VaTreeNode,
      VaTreeRoot,
      // VaInnerLoading,
      // VaSimplePalettePicker,
      // VaColorSquare,
      // VaSliderColorPicker,
      // VaAdvancedColorPicker,
      // VaColorPickerInput,
      VaPaletteCustom,
      // VaButtonDropdown,
    ].forEach(component => {
      Vue.component(component.name, component)
    })

    registerVuesticObject(Vue)

    Vue.mixin(ToastedMixin)
    Vue.use(BusPlugin)
    Vue.use(DropdownPopperPlugin)
    Vue.directive('sticky-scroll', StickyScroll)
  },
}

export default VuesticPlugin
