import smoothscroll from 'smoothscroll-polyfill'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import VueSweetalert2 from 'vue-sweetalert2'
import VueLazyLoad from 'vue-lazyload'
import JsonEditor from 'vue-json-edit'
import axios from '../store/axios'
import Filters from '../services/filters'
import Components from '../services/components'
import { i18n } from '../i18n' // loadLanguageAsync

import '../metrics'
import '../registerServiceWorker'

import 'nprogress/nprogress.css'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

if (process.env.VUE_APP_BUILD_VERSION) {
  // eslint-disable-next-line
  const message = `%c${'Build_information:'}\n %c${'Version'}: %c${VERSION},\n %c${'Timestamp'}: %c${TIMESTAMP},\n %c${'Commit'}: %c${COMMIT}`
  // eslint-disable-next-line
  console.info(
    message,
    'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;',
  )
}

// Polyfills
smoothscroll.polyfill()

Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.prototype.$http = axios

Vue.use(Filters)
Vue.use(Components)
Vue.use(VuesticPlugin)
Vue.use(VueSweetalert2)
Vue.use(JsonEditor)
Vue.use(VueLazyLoad, {
  loading: '/img/loading.gif',
})

Vue.use(ColorThemePlugin, {
  primary: '#005493',
  gray: '#333333',
})

/*
router.beforeEach((to, from, next) => {
  const lang = to.params.lang || 'eng'
  loadLanguageAsync(lang).then(() => next())
})
*/

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
})
