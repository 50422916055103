<template>
  <div
    class="flex"
    :class="sizes"
    v-show="show"
  >
    <va-notification
      color="danger"
      closeable
    >
      <va-badge color="danger">{{ $t('notifications.alerts.error') }}</va-badge>
      <span>
        {{ $t('notifications.network.error') }}
        <a
          href="#"
          @click.prevent="updateData()"
        >
          {{ $t('notifications.loading.retry') }}
        </a>
      </span>
    </va-notification>
  </div>
</template>

<script>
export default {
  name: 'retry-notification',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    sizes: {
      type: String,
      default: 'xs12',
    },
  },
  methods: {
    updateData () {
      this.$emit('retry')
    },
  },
}
</script>
