import lazyLoading from '../lazyLoading'

export default [
  {
    name: 'rolesIndex',
    path: 'index',
    meta: { requiresAuth: true, controller: 'DcpiRoles', action: 'index' },
    component: lazyLoading('trainings/roles/Index'),
  },
  {
    name: 'rolesView',
    path: 'view/:id',
    meta: { requiresAuth: true, controller: 'DcpiRoles', action: 'view' },
    component: lazyLoading('trainings/roles/View'),
  },
  {
    name: 'rolesEdit',
    path: 'edit/:id',
    meta: { requiresAuth: true, controller: 'DcpiRoles', action: 'edit' },
    component: lazyLoading('trainings/roles/Edit'),
  },
  {
    name: 'rolesNew',
    path: 'new',
    meta: { requiresAuth: true, controller: 'DcpiRoles', action: 'add' },
    component: lazyLoading('trainings/roles/New'),
  },
  {
    path: '',
    redirect: { name: 'rolesIndex' },
  },
]
