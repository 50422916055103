import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/components/auth/AuthLayout'
import AppLayout from '@/components/admin/AppLayout'
import RegionRoutes from './location/regions'
import CountryRoutes from './location/countries'
import { DistrictRoutes, DistrictPlanRoutes } from './location/districts'
import ReportRoutes from './reports'
import RegionReportRoutes from './reports/region'
import CountryReportRoutes from './reports/country'
import InvestmentRoutes from './reports/investments'
import AssignmentsRoutes from './reports/assignments'
import PreachingPointsRoutes from './reports/preachings'
import UsersRoutes from './users'
import ProfileRoutes from './users/profile'
import GroupsRoutes from './users/groups'
import NotificationsRoutes from './users/notifications'
import TrainingRoutes from './trainings'
import RolesRoutes from './trainings/roles'
import TrainingTypesRoutes from './trainings/types'
import TeamRoutes from './teams'
import PrayerRoutes from './prayers'
import FormRoutes from './forms'
import MtiRoutes from './mti'
import ResourcesRoutes from './resources'
import GoalsRoutes from './settings/goals'
import EvangelisticMethodsRoutes from './settings/evangelistic-methods'
import ThemesRoutes from './configuration/themes'
import ParametersRoutes from './settings/parameters'
import LanguagesRoutes from './configuration/languages'
import EmailTemplatesRoutes from './configuration/email-templates'
import LogsRoutes from './configuration/logs'
import ImportsDataRoutes from './configuration/imports'
import InventoryRequestsRoutes from './inventory/requests'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
  name: 'EmptyContainer',
}

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/admin/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          meta: { requiresAuth: false },
          component: () => import('@/components/auth/login/Login.vue'),
        },
        {
          name: 'signup',
          path: 'signup',
          meta: { requiresAuth: false },
          component: () => import('@/components/auth/signup/Signup.vue'),
        },
        {
          name: 'confirm',
          path: 'confirm',
          meta: { requiresAuth: false },
          component: () => import('@/components/auth/confirm-user/ConfirmUser.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          meta: { requiresAuth: false },
          component: () => import('@/components/auth/recover-password/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [
        {
          name: 'not-found-advanced',
          path: 'not-found-advanced',
          component: () => import('@/components/pages/404-pages/VaPageNotFoundSearch.vue'),
        },
        {
          name: 'not-found-simple',
          path: 'not-found-simple',
          component: () => import('@/components/pages/404-pages/VaPageNotFoundSimple.vue'),
        },
        {
          name: 'not-found-custom',
          path: 'not-found-custom',
          component: () => import('@/components/pages/404-pages/VaPageNotFoundCustom.vue'),
        },
        {
          name: 'not-found-large-text',
          path: '/pages/not-found-large-text',
          component: () => import('@/components/pages/404-pages/VaPageNotFoundLargeText.vue'),
        },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      redirect: { name: 'dashboard' },
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          meta: { requiresAuth: true, forcedPermission: true },
          component: () => import('@/components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          path: 'profile',
          component: EmptyParentComponent,
          children: [
            ...ProfileRoutes,
          ],
        },
        {
          path: 'location',
          component: EmptyParentComponent,
          children: [
            {
              path: 'regions',
              component: EmptyParentComponent,
              children: RegionRoutes,
            },
            {
              path: 'countries',
              component: EmptyParentComponent,
              children: CountryRoutes,
            },
            {
              path: 'districts',
              component: EmptyParentComponent,
              children: DistrictRoutes,
            },
          ],
        },
        {
          path: 'teams',
          component: EmptyParentComponent,
          children: [
            {
              path: 'prayers',
              component: EmptyParentComponent,
              children: PrayerRoutes,
            },
            {
              path: 'registry',
              component: EmptyParentComponent,
              children: TeamRoutes,
            },
            {
              path: 'plans',
              component: EmptyParentComponent,
              children: DistrictPlanRoutes,
            },
            {
              path: 'forms',
              component: EmptyParentComponent,
              children: FormRoutes,
            },
          ],
        },
        {
          path: 'reports',
          component: EmptyParentComponent,
          children: [
            ...ReportRoutes,
            {
              path: 'preaching-points',
              component: EmptyParentComponent,
              children: PreachingPointsRoutes,
            },
            {
              path: 'regions',
              component: EmptyParentComponent,
              children: RegionReportRoutes,
            },
            {
              path: 'countries',
              component: EmptyParentComponent,
              children: CountryReportRoutes,
            },
            {
              path: 'investments',
              component: EmptyParentComponent,
              children: InvestmentRoutes,
            },
            {
              path: 'assignments',
              component: EmptyParentComponent,
              children: AssignmentsRoutes,
            },
          ],
        },
        {
          path: 'mti',
          component: EmptyParentComponent,
          children: MtiRoutes,
        },
        {
          path: 'members',
          component: EmptyParentComponent,
          children: [
            ...UsersRoutes,
            {
              path: 'groups',
              component: EmptyParentComponent,
              children: GroupsRoutes,
            },
            {
              path: 'notifications',
              component: EmptyParentComponent,
              children: NotificationsRoutes,
            },
          ],
        },
        {
          path: 'trainings',
          component: EmptyParentComponent,
          children: [
            ...TrainingRoutes,
            {
              path: 'roles',
              component: EmptyParentComponent,
              children: RolesRoutes,
            },
            {
              path: 'types',
              component: EmptyParentComponent,
              children: TrainingTypesRoutes,
            },
          ],
        },
        {
          path: 'resources',
          component: EmptyParentComponent,
          children: ResourcesRoutes,
        },
        {
          path: 'inventory',
          component: EmptyParentComponent,
          children: [
            {
              path: 'requests',
              component: EmptyParentComponent,
              children: InventoryRequestsRoutes,
            },
          ],
        },
        {
          path: 'settings',
          component: EmptyParentComponent,
          children: [
            {
              path: 'goals',
              component: EmptyParentComponent,
              children: GoalsRoutes,
            },
            {
              path: 'methods',
              component: EmptyParentComponent,
              children: EvangelisticMethodsRoutes,
            },
            {
              path: 'parameters',
              component: EmptyParentComponent,
              children: ParametersRoutes,
            },
          ],
        },
        {
          path: 'configuration',
          component: EmptyParentComponent,
          children: [
            {
              path: 'themes',
              component: EmptyParentComponent,
              children: ThemesRoutes,
            },
            {
              path: 'emails',
              component: EmptyParentComponent,
              children: EmailTemplatesRoutes,
            },
            {
              path: 'languages',
              component: EmptyParentComponent,
              children: LanguagesRoutes,
            },
            {
              path: 'logs',
              component: EmptyParentComponent,
              children: LogsRoutes,
            },
            {
              path: 'import',
              component: EmptyParentComponent,
              children: ImportsDataRoutes,
            },
          ],
        },
      ],
    },
  ],
})
